<template>
    <div class='collection-view view view--collection'>
        <div class='view__content' data-scroll-section>
            <CollectionTier
                id='collection'
                :slug='slug'
                :isShowHeading='true'
                data-scroll-id='collection'
                data-scroll
            />
        </div>

        <div data-scroll-section>
            <Footer id='footer' data-scroll-id='footer' data-scroll />
        </div>
    </div>
</template>


<script>
import CollectionTier from '^/components/tiers/CollectionTier.vue'
import Footer from '^/components/Footer.vue'

export default {
    name: 'CollectionView',
    props: {
        slug: {
            type: String,
            required: true
        }
    },
    components: {
        CollectionTier,
        Footer
    }
};
</script>