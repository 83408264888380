<template>
    <span class='kernable' v-html='wrappedText'></span>
</template>

<script>


export default {
    name: 'Kernable',
    props: {
        text: {
            type: String,
            required: true
        },
        uppercase: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        wrappedText () {
            let text = (this.uppercase ? this.text.toUpperCase() : this.text);
            return text.replace(/\S/g, '<span data-ch="c-$&">$&</span>');
        }
    }
};
</script>