<template>

<section class='collection' :style='{
    "--collection-title-left-offset": titleLeftOffset,
    "--collection-title-scale": titleScale
}'>
    <div class='collection__grid grid'>
        <div class='collection__grid__col grid__col'>
            <div ref='content' class='collection__content'>

                <div v-if='isShowHeading' class='collection__heading' :class='{"collection__heading--is-title-case": isTitleCase}' :style="!collection ? { opacity: 0 } : {}">
                    <h2 ref='title'
                        class='collection__heading__title collection__heading__title--1'
                        :style='`width: ${contentWidth}px`'
                        v-text='collection?.title || "Collection"'
                    />

                    <!-- duplicate title for glow; html gets set after text fitting -->
                    <h2 class='collection__heading__title collection__heading__title--2'
                        :style='`width: ${contentWidth}px`'
                        v-html='titleHtml || ""'
                    />
                </div>

                <transition name="vue-fade">
                    <Spinner
                        v-if='!collection'
                        :variants='["large", "absolute"]'
                    />

                    <div v-else class='collection__items'>
                        <router-link v-for='(item, i) in products'
                            class='collection__item'
                            :class='{
                                "collection__item--has-hover-img": item.media.length > 1
                            }'
                            :key='i'
                            :to='{ name: "product", params: { slug: item.handle }}'>
                            
                            <div class='collection__item__thumb'>
                                <div v-if='item.media.length > 0' class='collection__item__thumb__default'>
                                    <Media :media='item.media[0]' :isFullbleed='true' :alt="item.media[0]?.alt || item.title" />
                                </div>
                                <div v-if='item.media.length > 1' class='collection__item__thumb__hover'>
                                    <Media :media='item.media[1]' :isFullbleed='true' :alt="item.media[1]?.alt || `Second image for ${item.title}`" />
                                </div>
                            </div>
                            <div class='collection__item__info'>
                                <div class='collection__item__info__title'>
                                    <span v-for='(word, j) in productTitleArray(item.title)' :key='`word-${j}`' v-text='word + "&#32;"' />
                                </div>
                                <div class='collection__item__info__price'>
                                    <template v-if='item.price === 0'>
                                        <span class='collection__item__info__price__free' v-text='"Free"' />
                                    </template>
                                    <template v-else>
                                        <span class='collection__item__info__price__sign' v-text='"$"' />
                                        <span class='collection__item__info__price__dollars' v-text='getPriceDollars(item.price)' />
                                        <span class='collection__item__info__price__dot' v-text='"."' />
                                        <span class='collection__item__info__price__cents' v-text='getPriceCents(item.price)' />
                                    </template>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </transition>

                
            </div>


        </div>
    </div>
</section>

</template>

<script>
import { productTitleArray } from '^/utils/typography'
import { getCssValueInPx } from '^/utils/markup'
import textFit from 'textfit'
import Media from '^/components/elements/Media.vue'
import Spinner from '^/components/elements/Spinner.vue'

export default {
    name: 'CollectionTier',
    props: {
        slug: {
            type: String,
            required: true
        },
        isShowHeading: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        collection: null,
        products: [],

        isFontsLoaded: document.fonts.check("12px 'PP Fragment Variable Web'"),
        isTitleCase: true,
        contentWidth: 0,
        titleLeftOffset: '-0.06em',
        titleRightOffset: '-0.06em',
        titleScale: 1,
        titleHtml: '',
        resizeObserver: null
    }),
    watch: {
        isFontsLoaded (isFontsLoaded) {
            this.fitTitleText()
        },
        collection () {
            if (this.collection?.title?.toLowerCase().charAt(0) === 'a') {
                this.titleLeftOffset = '0em'
            }

            this.fitTitleText()
        }
    },
    created () {
        fetch(`/collections/${this.slug}?view=json`).then(response => {
            return response.json()
        }).then(data => {
            this.collection = data.collection
            this.products = data.products
        })

        if (!this.isFontsLoaded) {
            document.fonts.ready.then(() => this.isFontsLoaded = true);
        }
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(this.onResize)
        this.resizeObserver.observe(this.$refs.content)
    },
    updated() {
        // this.fitTitleText()
    },
    beforeUnmount() {
        this.resizeObserver?.disconnect()
    },
    methods: {
        onResize (e) {
            this.contentWidth = Math.floor(e[0]?.contentRect?.width || 0)
            this.fitTitleText()
        },
        fitTitleText () {
            const $title = this.$refs.title
            if (!$title || !this.isFontsLoaded || !this.collection) return

            this.titleScale = 1

            this.$nextTick(() => {
                textFit($title, {
                    // multiLine: true,
                    widthOnly: true,
                    minFontSize: 24,
                    maxFontSize: this.isTitleCase ? 400 : 300,
                    reProcess: true
                })

                this.titleHtml = $title.innerHTML

                // measure values for scaling the element via css to account for
                // the left and right edges of the text being inset from the element
                // when the font is large
                const leftOffset = getCssValueInPx(this.titleLeftOffset, $title)
                const rightOffset = getCssValueInPx(this.titleRightOffset, $title)
                this.titleScale = 1 - (leftOffset / this.contentWidth) - (rightOffset / this.contentWidth)
            })
        },
        getPriceDollars (price) {
            return Math.floor(price / 100)
        },
        getPriceCents (price) {
            return price % 100 || '00'
        },
        productTitleArray: productTitleArray
    },
    components: {
        Media,
        Spinner
    }
};
</script>