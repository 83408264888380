<template>
    <footer class='footer'>
        <div class='footer__prefooter' v-if='isShowPrefooter'>
            <img class='footer__prefooter__img' alt='Hazbin Hotel Outside' src='@/media/footer-img-png8.png' width='2560' height='2560' loading='lazy'>
        </div>

        <div class='footer__newsletter'>
            <div class='footer__newsletter__content'>
                <NewsletterTier />
            </div>
        </div>

        <div class='footer__main'>
            <div class='footer__main__content'>

                <div class='footer__main__content__left'>
                    <a class='footer__link footer__link--insta' href='https://www.instagram.com/hazbinhotel' rel='noopener' target='_blank' aria-label="Instagram">
                        <SvgFooterSocialInsta />
                    </a>
                    <a class='footer__link footer__link--tiktok' href='https://www.tiktok.com/@officialhazbinhotel' rel='noopener' target='_blank' aria-label="TikTok">
                        <SvgFooterSocialTikTok />
                    </a>
                    <a class='footer__link footer__link--twitter' href='https://twitter.com/hazbinhotel' rel='noopener' target='_blank' aria-label="Twitter">
                        <SvgFooterSocialTwitter />
                    </a>
                    <a class='footer__link footer__link--youtube' href='https://www.youtube.com/@SpindleHorse/videos' rel='noopener' target='_blank' aria-label="YouTube">
                        <SvgFooterSocialYouTube />
                    </a>
                </div>

                <div class='footer__main__content__right'>
                    <a class='footer__link footer__link--terms' href='https://a24films.com/terms-of-use' rel='noopener' target='_blank' aria-label="Legal terms">
                        <SvgFooterLegalTerms />
                    </a>
                    <a class='footer__link footer__link--privacy' href='https://a24films.com/privacy-policy' rel='noopener' target='_blank' aria-label="Legal privacy">
                        <SvgFooterLegalPrivacy />
                    </a>
                    <!-- <a class='footer__link footer__link--dontsell' href='/pages/ccpa-opt-out'>
                        <SvgFooterLegalDontSell />
                    </a> -->
                    <a class='footer__link footer__link--dontsell' @click='showConsentPrefs' aria-label="Privacy consent preferences">
                        <SvgFooterLegalDontSell />
                    </a>
                    <a class='footer__link footer__link--a24' href='https://a24films.com/' rel='noopener' target='_blank' aria-label="A24 website">
                        <SvgFooterLogoA24 />
                    </a>
                    <a class='footer__link footer__link--bento' href='https://www.bentoboxent.com/' rel='noopener' target='_blank' aria-label="Bento Box Entertainment website">
                        <SvgFooterLogoBento />
                    </a>
                </div>

            </div>
        </div>
    </footer>
</template>

<script>
import NewsletterTier from '^/components/tiers/NewsletterTier.vue';
import SvgFooterLegalDontSell from '^/components/svg/SvgFooterLegalDontSell.vue';
import SvgFooterLegalPrivacy from '^/components/svg/SvgFooterLegalPrivacy.vue';
import SvgFooterLegalTerms from '^/components/svg/SvgFooterLegalTerms.vue';
import SvgFooterLogoA24 from '^/components/svg/SvgFooterLogoA24.vue';
import SvgFooterLogoBento from '^/components/svg/SvgFooterLogoBento.vue';
import SvgFooterSocialInsta from '^/components/svg/SvgFooterSocialInsta.vue';
import SvgFooterSocialTikTok from '^/components/svg/SvgFooterSocialTikTok.vue';
import SvgFooterSocialTwitter from '^/components/svg/SvgFooterSocialTwitter.vue';
import SvgFooterSocialYouTube from '^/components/svg/SvgFooterSocialYouTube.vue';

export default {
    name: 'Footer',
    props: {
        isShowPrefooter: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        showConsentPrefs () {
            window.A24Consent?.togglePreferenceCenter()
        }
    },
    components: {
        NewsletterTier,
        SvgFooterLegalDontSell,
        SvgFooterLegalPrivacy,
        SvgFooterLegalTerms,
        SvgFooterLogoA24,
        SvgFooterLogoBento,
        SvgFooterSocialInsta,
        SvgFooterSocialTikTok,
        SvgFooterSocialTwitter,
        SvgFooterSocialYouTube
    }
};
</script>