import { defineStore } from 'pinia';

export const useCartStore = defineStore('cart', {
    state: () => {
        return {
            cart: null,
            cartMetafields: null,
            cartError: null,
            isCartUpdating: true,
            isCartDrawerOpen: window.app?.isCartDrawerOpen || false
        }
    },
    actions: {
        getCart () {
            /* fetch(window.Shopify.routes.root + 'cart.js').then(response => {
                return response.json()
            }).then(cart => {
                this.cart = cart
                this.isCartUpdating = false
            }) */

            fetch('/cart?view=json').then(response => {
                return response.json()
            }).then(data => {
                this.cart = data.cart || {}
                this.cartMetafields = data.metafields || {}
                this.isCartUpdating = false
            })
        },
        addToCart (variant, quantity = 1, isOpenCart = true) {
            this.isCartUpdating = true

            fetch(window.Shopify.routes.root + 'cart/add.js', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'items': [{
                        id: variant.id,
                        quantity: quantity
                    }]
                })
            }).then(() => {
                this.getCart()
                if (isOpenCart) this.openCartDrawer()
            })
        },
        updateLineItem (lineItem, quantity) {
            this.isCartUpdating = true

            const updates = {}
            updates[lineItem.key] = quantity

            fetch(window.Shopify.routes.root + 'cart/update.js', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ updates })
            }).then(async response => {
                if (!response.ok) {
                    const error = await response.json()
                    throw Error(error.message)
                }
                return response.json()
            }).then(() => {
                this.cartError = null
                this.getCart()
            }).catch((error) => {
                this.cartError = error.message
                this.isCartUpdating = false
            })
        },
        increaseLineItem (lineItem) {
            this.updateLineItem(lineItem, lineItem.quantity + 1)
        },
        decreaseLineItem (lineItem) {
            this.updateLineItem(lineItem, lineItem.quantity - 1)
        },
        removeLineItem (lineItem) {
            this.updateLineItem(lineItem, 0)
        },

        openCartDrawer () {
            this.isCartDrawerOpen = true
        },
        closeCartDrawer () {
            this.isCartDrawerOpen = false
        },
        toggleCartDrawer () {
            this.isCartDrawerOpen = !this.isCartDrawerOpen
        }
    }
});