<template>
   <section class='superhero' :class='classes'>
        <div class='superhero__grid grid'>
            <div class='superhero__grid__col superhero__grid__col--title grid__col'>
                <!-- <h1 v-for='i in 2' class='superhero__title' :class='`superhero__title--${i}`'>
                    <span class='superhero__title__line superhero__title__line--1'>
                        Merch
                    </span>
                    <span class='superhero__title__line superhero__title__line--2'>
                        Avail&ndash;
                    </span>
                    <span class='superhero__title__line superhero__title__line--3'>
                        able
                    </span>
                    <span class='superhero__title__line superhero__title__line--4'>
                        Now!
                    </span>
                </h1> -->

                <h1 v-for='i in 2' class='superhero__title superhero__title--bigger' :class='`superhero__title--${i}`'>
                    <span class='superhero__title__line superhero__title__line--1'>
                        The
                    </span>
                    <span class='superhero__title__line superhero__title__line--2'>
                        Official
                    </span>
                    <span class='superhero__title__line superhero__title__line--3'>
                        Merch
                    </span>
                    <span class='superhero__title__line superhero__title__line--4'>
                        Store
                    </span>
                </h1>

                <div class='superhero__actions superhero__actions--for-bigger-title'>
                    <router-link class='superhero__actions__cta' :to='{name: "home", hash: "#products"}'>
                        <span class='superhero__actions__cta__text'>
                            <span>See </span><span>products</span>
                        </span>
                        <span class='superhero__actions__cta__caret'>
                            <SvgIconCaretDown />
                        </span>
                    </router-link>
                </div>

                <a v-if='primeVideoProductLink' aria-label="Watch now on Amazon Prime" :href='primeVideoProductLink' class='superhero__badge superhero__badge--for-bigger-title superhero__badge--is-linked' rel='noopener' target='_blank'>
                    <SvgIconPrimeBadge :isWatchable='true'/>
                </a>
                <div v-else class='superhero__badge superhero__badge--for-bigger-title' aria-label="Coming soon on Amazon Prime">
                    <SvgIconPrimeBadge :isWatchable='true' />
                </div>
            </div>

            <div class='superhero__grid__col superhero__grid__col--media grid__col'>
                <div class='superhero__media'>
                    <div class='superhero__media__foreground' :style='characterImage.style'>
                        <img class='superhero__media__foreground__img' :src='isUseLossyCharacterImagePngs ? characterImage.srcLossy : characterImage.src' :alt='characterImage.name' />
                    </div>
                    
                    <div class='superhero__media__background'>
                        <video ref='video' class='superhero__media__video' xmlns='http://www.w3.org/1999/xhtml' width='306' height='382' loop muted playsinline preload='auto' poster='@/media/smile-poster.jpg'>
                            <source src='@/media/smile.mp4' type='video/mp4' />
                        </video>
                    </div>
                </div>
            </div>
        </div>
   </section>
</template>

<script>
import { mapActions } from 'pinia';
import { useScrollStore } from '^/stores/scroll';
import SvgIconCaretDown from '^/components/svg/SvgIconCaretDown.vue';
import SvgIconPrimeBadge from '^/components/svg/SvgIconPrimeBadge.vue';

export default {
    name: 'SuperheroTier',
    data: () => ({
        inView: true,
        isUseLossyCharacterImagePngs: true
    }),
    computed: {
        classes () {
            return {
                'state-inview': this.inView
            }
        },
        characterImage () {
            const images = [
                {
                    name: 'Charlie',
                    weight: 1,
                    src: new URL('@/media/hero.charlie.recrop.png', import.meta.url).href,
                    srcLossy: new URL('@/media/hero.charlie.recrop.lossy.png', import.meta.url).href
                },
                {
                    name: 'Vox',
                    weight: 1,
                    src: new URL('@/media/hero.vox.png', import.meta.url).href,
                    srcLossy: new URL('@/media/hero.vox.lossy.png', import.meta.url).href,
                    style: {
                        transform: 'translateX(12.5%)'
                    }
                },
                {
                    name: 'Angel Dust',
                    weight: 1,
                    src: new URL('@/media/hero.angeldust.png', import.meta.url).href,
                    srcLossy: new URL('@/media/hero.angeldust.lossy.png', import.meta.url).href
                },
                {
                    name: 'Alastor',
                    weight: 1,
                    src: new URL('@/media/hero.alastor.png', import.meta.url).href,
                    srcLossy: new URL('@/media/hero.alastor.lossy.png', import.meta.url).href,
                    style: {
                        transform: 'scaleX(-1)'
                    }
                },
                {
                    name: 'Husk',
                    weight: 3,
                    src: new URL('@/media/hero.husk.png', import.meta.url).href,
                    srcLossy: new URL('@/media/hero.husk.lossy.png', import.meta.url).href,
                    style: {
                        transform: 'translateX(15%)'
                    }
                },
                {
                    name: 'Lucifer',
                    weight: 3,
                    src: new URL('@/media/hero.lucifer.png', import.meta.url).href,
                    srcLossy: new URL('@/media/hero.lucifer.lossy.png', import.meta.url).href,
                    style: {
                        transform: 'translateX(2%)'
                    }
                },
                {
                    name: 'Vaggie',
                    weight: 3,
                    src: new URL('@/media/hero.vaggie.png', import.meta.url).href,
                    srcLossy: new URL('@/media/hero.vaggie.lossy.png', import.meta.url).href
                },
                {
                    name: 'Velvette',
                    weight: 3,
                    src: new URL('@/media/hero.velvette.png', import.meta.url).href,
                    srcLossy: new URL('@/media/hero.velvette.lossy.png', import.meta.url).href,
                    style: {
                        transform: 'translateX(9%)'
                    }
                }
            ]

            const totalWeight = images.reduce((sum, image) => sum + image.weight, 0);

            let random = Math.random() * totalWeight;

            for (const image of images) {
                random -= image.weight;
                if (random <= 0) {
                    return image;
                }
            }
        },
        primeVideoProductLink () {
            return window.app.primeVideoProductLink || 'https://www.amazon.com/Hazbin-Hotel-Season-1/dp/B0CLM8LHS7'
        }
    },
    mounted () {
        this.observer = new window.IntersectionObserver(this.intersect);
        this.observer.observe(this.$el);
    },
    beforeUnmount () {
        this.observer.unobserve(this.$el);
    },
    methods: {
        ...mapActions(useScrollStore, [
            'scrollTo'
        ]),
        intersect (entries) {
            if (entries[0].isIntersecting) {
                this.inView = true;
                this.$refs.video.play();
            } else {
                this.inView = false;
                this.$refs.video.pause();
            }
        }
    },
    components: {
        SvgIconCaretDown,
        SvgIconPrimeBadge
    }
};
</script>