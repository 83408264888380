<template>
    <div class='video'>
        <div class='video__player' tabindex='0' @keydown='playerKeydown'>

            <transition name='vue-fade'>
                <div class='video__player__poster' v-if='!hasClickedPlay'>
                    <img :src='posterSrc' alt='Video Poster' loading='lazy'/>
                    <a class='video__player__play' @click='toggleVideo' aria-label='Play' role='button'>
                        <SvgIconCaretDown />
                    </a>
                </div>
            </transition>

            <transition name='vue-fade'>
                <div class='video__player__video' v-if='hasClickedPlay'>
                    <iframe 
                        class='video__player__video__embed'
                        :src='src'
                        frameborder='0' 
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' 
                        allowfullscreen>
                    </iframe>
                </div>
            </transition>

        </div>

        <div class='video__caption' v-if='caption'>
            <div class='video__caption__text' v-text='caption' />
        </div>
    </div>
</template>

<script>
const SPACE_KEYCODE = [0, 32];
const ENTER_KEYCODE = 13;
const ESC_KEYCODE = 27;

import SvgIconCaretDown from '^/components/svg/SvgIconCaretDown.vue';

export default {
    name: 'VideoBlock',
    props: {
        videoType: {
            type: String,
            required: true
        },
        embedId: {
            type: String,
            required: true
        },
        posterSrc: {
            type: String,
            required: true
        },
        caption: {
            type: String,
            required: false
        },
    },
    data () {
        return {
            hasClickedPlay: false
        }
    },
    computed: {
        src () {
            let src = '';

            if (this.videoType == 'vimeo') {
                src = `https://player.vimeo.com/video/${this.embedId}?h=b260a3b1b0&autoplay=1&title=0&byline=0&portrait=0&color=e93b36`;
            } else if (this.videoType == 'youtube') {
                src = `https://www.youtube.com/embed/${this.embedId}?autoplay=1&color=white&disablekb=1&modestbranding=1&playsinline=1`;
            }

            return src;
        }
    },
    methods: {
        playerKeydown (e) {
            switch (e.keyCode) {
                case SPACE_KEYCODE:
                    e.preventDefault();
                    this.toggleVideo();
                    return;

                case ENTER_KEYCODE:
                    this.toggleVideo();
                    return;

                case ESC_KEYCODE:
                    this.toggleVideo();
                    return;

                default:
                    return;
            }
        },

        toggleVideo () {
            this.hasClickedPlay = !this.hasClickedPlay
        },
        closeVideo () {
            this.hasClickedPlay = !this.hasClickedPlay
        }
    },
    components: {
        SvgIconCaretDown
    }
};
</script>