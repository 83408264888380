import { defineStore } from 'pinia';
import LoconativeScroll from '@/lib/loconative-scroll';

import '@/lib/loconative-scroll/index.scss';

export const useScrollStore = defineStore('scroll', {
    state: () => {
        return {
            scroller: null,
            scrollY: 0,
            scrollVelocity: 0,
            scrollDirection: 1, // 1 is down, -1 is up
            isScrolled: false,

            inviewElements: null,
            currentSection: null,
            currentSectionId: null,

            scrollWidth: document.documentElement.scrollWidth,
            scrollHeight: document.documentElement.scrollHeight,
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,

            isScrollToActive: false
        }
    },
    actions: {
        setupScroller () {
            this.scroller = new LoconativeScroll({
                smooth: true,
                offset: ['95%', '5%'],
                tablet: {
                    smooth: false,
                    breakpoint: 900
                },
                smartphone: {
                    smooth: false
                }
            })

            this.scroller.on('scroll', this.updateScrollPosition)

            // update initial scroll position
            this.updateScrollPosition()
        },
        updateScroller () {
            if (this.isScrollToActive) {
                setTimeout(() => this.updateScroller(), 100)
                // console.log('updateScroller: retry')
                return
            }
            this.scroller?.destroy()
            this.setupScroller()
            this.updateScrollPosition()
            // console.log('updateScroller: done')
        },
        updateScrollPosition (scrollObj) {
            // if called w/o the scroll instance object, get it from the scroller proxy
            if (!scrollObj && this.scroller?.scroll.instance) {
                scrollObj = JSON.parse(JSON.stringify(this.scroller.scroll.instance))
            }

            const scrollY = scrollObj.scroll.y
            const inviewEls = scrollObj.currentElements

            this.scrollY = scrollY
            this.scrollVelocity = scrollObj.speed
            this.scrollDirection = scrollObj.direction === 'down' ? 1 : -1
            this.isScrolled = scrollY > 66.6

            if (inviewEls) {
                const currentSection = Object.values(inviewEls).find(el => {
                    return !!el.el?.dataset?.scrollId
                })
                
                this.inviewElements = inviewEls
                this.currentSection = currentSection || null
                this.currentSectionId = currentSection?.id || null

            }
        },
        updatePageSizes () {
            this.scrollWidth = document.documentElement.scrollWidth
            this.scrollHeight = document.documentElement.scrollHeight
            this.winWidth = window.innerWidth
            this.winHeight = window.innerHeight
        },
        scrollTo (target) {
            this.isScrollToActive = true
            this.scroller?.scrollTo(target, {
                onComplete: () => {
                    this.isScrollToActive = false
                }
            })
        }
    }
})