const widont = (text, sep = '&nbsp;') => {
    /* Adapted from https://github.com/ekalinin/typogr.js
    /* Replaces the space between the last two words in a string with ``&nbsp;``
    /* Works in these block tags ``(h1-h6, p, li, dd, dt)`` and also accounts for
    /* potential closing inline elements ``a, em, strong, span, b, i`` */

    var inline_tags = 'a|em|span|strong|i|b'
    var word = '(?:<(?:'+inline_tags+')[^>]*?>)*?[^\\s<>]+(?:</(?:'+inline_tags+')[^>]*?>)*?'
    var re_widont = new RegExp(
        // matching group 1:
        // 1. space and a word with a possible bordering tag
        // 2. space and a word with a possible bordering tag
        '(' +
            '\\s+' + word +
            '\\s+' + word +
        ')' +

        // one or more space characters
        '(?:\\s+)' +

        // matching group 2:
        // 1. nontag/nonspace characters
        // 2. one or more inline closing tags can be
        //    surrounded by spaces and followed by a period
        // 3. allowed closing tags or end of line
        '(' +
            '[^<>\\s]+' +
            '(?:\\s*</(?:a|em|span|strong|i|b)[^>]*?>\\s*\\.*)*?' +
            '(?:\\s*?</(?:p|h[1-6]|li|dt|dd)>|$)' +
        ')',
    'gi');
    
    return text.replace(re_widont, '$1<span class="widont">' + sep + '</span>$2');
};

const productTitleArray = (text) => {
    // if the title ends with text inside [brackets], treat that as a subtitle
    const splitTitle = text.match(/^(.*?)(?:\s*\[(.*?)\])?$/)
    return splitTitle ? splitTitle.slice(1).filter(Boolean) : [text]
}

export {
    widont,
    productTitleArray
};
