<template>
    <div class='spinner' :class='modifierClasses' />
</template>

<script>
import { getModifierClasses } from '^/utils/markup'

export default {
    props: {
        variants: {
            type: [String, Array],
            default: ''
        }
    },
    computed: {
        modifierClasses () {
            return getModifierClasses('spinner', this.variants)
        }
    }
}
</script>