<template>
    <section class='faq'>

        <div class='faq__heading'>
            <h1 class='faq__heading__text'>
                <Kernable text='FAQs' />
            </h1>
        </div>

        <div class='faq__nav' v-if='faqs'>
            <template v-for='(section, i) in faqs' :key='`faq-nav--${i}`'>
                <a class='faq__nav__tab'
                    v-text='section.name'
                    v-on:click='setCurrentSection(section)'
                    :class='{"state-active": section.name === currentSection?.name}'
                />
            </template>
        </div>

        <template v-if='faqs' v-for='(section, i) in faqs' :key='`faq-section--${i}`'>
            <div class='faq__items' v-if='section.name === currentSection?.name'>
                <div class='faq__item' v-for='(faq, j) in section.faqs' :key='j'>
                    <div class='faq__item__content'>
                        <div class='faq__item__question' :class='"faq__item__question--" + (j + 1)'>
                            <p v-html='faq.question' />
                        </div>
                        <div class='faq__item__answer copy' v-html='faq.answer' />
                    </div>
                </div>
            </div>
        </template>

    </section>
</template>


<script>
import { mapActions } from 'pinia'
import { useScrollStore } from '^/stores/scroll'
import { widont } from '^/utils/typography'
import Kernable from '^/components/elements/Kernable.vue'

export default {
    name: 'FaqTier',
    data() {
        return {
            currentSection: null
        }
    },
    computed: {
        faqs() {
            const faqs = window.app.faqs || [];
            const faqsSorted = faqs.sort((a, b) => a.sort_order - b.sort_order);
            return faqsSorted.map((section) => {
                return { 
                    ...section,
                    faqs: this.processFaqText(section.faq_text)
                };
            });
        }
    },
    mounted() {
        this.currentSection = this.faqs[0];

        // deeplink to faqs with a url hash like #faq-<section>-<question number>
        if (this.$route.hash) {
            const hashSplit = this.$route.hash.split('-');
            const isFaqHash = hashSplit[0] === '#faq'
            const faqSectionName = hashSplit[1] || '';
            const faqSection = this.faqs.find((section) => section.name.toLowerCase() === faqSectionName)

            if (!isFaqHash || !faqSection) return

            setTimeout(() => {
                this.setCurrentSection(faqSection)
                this.$nextTick(() => {
                    this.scrollTo('.faq__item__question--' + (hashSplit[2]?.toString() || '1'))
                })
            }, 600)
        }
    },
    methods: {
        ...mapActions(useScrollStore, [
            'scrollTo'
        ]),
        setCurrentSection (section) {
            this.currentSection = section;
        },
        widont (text) {
            return widont(text, " ")
        },
        unescape (string, widont = true) {
            const doc = new DOMParser().parseFromString(string, 'text/html');
            let text = doc.documentElement.textContent.replace('&nbsp;', ' ');
            if (widont) text = this.widont(text);
            return text
        },
        processFaqText (text) {
            const textUnescaped = this.unescape(text, false);
            const blocks = textUnescaped.split(/\n-{3,}\n/);
            const objects = blocks.map((block) => {
                const lines = block.trim().split('\n');
                const obj = {};
                lines.forEach((line, index) => {
                    if (line.startsWith('#')) {
                        obj.question = this.widont(line.slice(2));
                    } else {
                        const linkRegex = /((http|https):[^\s]+)/g;
                        const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)(?<!\.)/gi;
                        const formattedLine = `<p>${line.replace(emailRegex, '<a href="mailto:$1" target="_blank">$1</a>').replace(linkRegex, '<a href="$1" target="_blank">$1</a>')}</p>`;
                        if (index === 1) {
                            obj.answer = this.widont(formattedLine);
                        } else {
                            obj.answer += this.widont(formattedLine);
                        }
                    }
                });
                return obj;
            });
            return objects;
        }
    },
    components: {
        Kernable
    }
};
</script>