<template>
    <div class='splash__bg' :style='style'>
        <div class='splash__bg__layer splash__bg__layer--1'>
            <img class='splash__bg__layer__img' alt='Hazbin Hotel logo' src='@/media/splash-bg-layer-1.jpg' width='3772' height='2950'>
        </div>

        <div class='splash__bg__layer splash__bg__layer--2'>
            <img class='splash__bg__layer__img' alt='Hazbin Hotel logo' src='@/media/splash-bg-layer-2.png' width='3772' height='2950'>
        </div>

        <div class='splash__bg__layer splash__bg__layer--3'>
            <img class='splash__bg__layer__img' alt='Hazbin Hotel logo' src='@/media/splash-bg-layer-3.png' width='3772' height='2950'>
        </div>

        <div class='splash__bg__layer splash__bg__layer--4'>
            <img class='splash__bg__layer__img' alt='Hazbin Hotel logo' src='@/media/splash-bg-layer-4.png' width='3772' height='2950'>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SplashBackground',
    props: {
        isModalOpen: {
            required: true
        },
        showIdleAnimation: {
            default: true,
            type: Boolean
        },
        timeToIdle: {
            default: 2000,
            type: Number
        }
    },
    data: () => ({
        mouse: {
            x: 0,
            y: 0
        },
        pos: {
            x: 0,
            y: 0
        },
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
        isWinVisible: document.visibilityState === 'visible',
        isIdle: false
    }),
    computed: {
        isPlaying () {
            return !this.isModalOpen && this.isWinVisible;
        },
        style () {
            return {
                '--x': this.pos.x,
                '--y': this.pos.y
            }
        }
    },
    watch: {
        isPlaying: {
            immediate: true,
            handler: function () {
                this.loop();
            }
        }
    },
    mounted () {
        this.resetIdle();

        window.addEventListener('resize', this.resize);
        document.addEventListener('visibilitychange', this.visibilitychange);
        this.$parent.$el.addEventListener('mousemove', this.mousemove);
        this.$parent.$el.addEventListener('touchstart', this.touch);
        this.$parent.$el.addEventListener('touchmove', this.touch);
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.resize);
        document.removeEventListener('visibilitychange', this.visibilitychange);
        this.$parent.$el.removeEventListener('mousemove', this.mousemove);
        this.$parent.$el.removeEventListener('touchstart', this.touch);
        this.$parent.$el.removeEventListener('touchmove', this.touch);
    },
    methods: {
        loop () {
            if (!this.isPlaying) return;

            if (this.isIdle && this.showIdleAnimation) {
                this.idleDraw();
                requestAnimationFrame(this.loop);
            } else {
                this.mouseDraw();
                requestAnimationFrame(this.loop);
            }
        },

        mouseDraw () {
            this.pos.x += (this.mouse.x - this.pos.x) * 0.05;
            this.pos.y += (this.mouse.y - this.pos.y) * 0.05;
        },

        idleDraw () {
            const idleCycleLength = 15000;
            const circleWidth = 0.5;

            const cycleCompletion = (Date.now() % idleCycleLength) / idleCycleLength;

            const cosine = Math.cos(cycleCompletion * Math.PI * 2);
            const sine = Math.sin(cycleCompletion * Math.PI * 2);

            const x = (cosine / 2 + 0.5) * circleWidth - circleWidth/2;
            const y = circleWidth - (sine / 2 + 0.5) * circleWidth - circleWidth/2;
            
            const idleTime = Date.now() - this.idleStartTime;
            const lerpTime = 5000;

            // if beginning of idle animation, lerp to circle path
            if (idleTime < lerpTime) {
                const minLerpAmt = 0.02;
                const maxLerpAmt = 0.15;
                const lerpAmt = idleTime * (maxLerpAmt - minLerpAmt) / lerpTime + minLerpAmt;

                this.pos.x += (x - this.pos.x) * lerpAmt;
                this.pos.y += (y - this.pos.y) * lerpAmt;
            } else {
                this.pos.x = x;
                this.pos.y = y;
            }
        },
        resetIdle () {
            this.isIdle = false;

            clearTimeout(this.idleTimeout);

            this.idleTimeout = setTimeout(() => {
                this.idleStartTime = Date.now();
                this.isIdle = true;
            }, this.timeToIdle);
        },

        offsetX (x) {
            return (-1 * ((this.winWidth / 2) - x)) / this.winWidth;
        },
        offsetY (y) {
            return (-1 * ((this.winHeight / 2) - y)) / this.winHeight;
        },

        // handlers
        resize () {
            this.winWidth = window.innerWidth;
            this.winHeight = window.innerHeight
        },
        visibilitychange () {
            this.isWinVisible = document.visibilityState === 'visible';
        },
        mousemove (e) {
            this.resetIdle();

            this.mouse.x = this.offsetX(e.pageX);
            this.mouse.y = this.offsetY(e.pageY);
        },
        touch (e) {
            this.resetIdle();

            const touches = e.originalEvent ? e.originalEvent.touches[0] : e.touches[0];
            this.mouse.x = this.offsetX(touches.pageX);
            this.mouse.y = this.offsetY(touches.pageY);
        }
    }
}
</script>
