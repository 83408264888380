<template>
    <div class='particles' :style='style'>
        <div class='particles__layer particles__layer--1' v-if='isElementVisible'>
            <img class='particles__layer__img' alt='Particles Background' src='@/media/particles-layer-1.png' width='3772' height='2950'>
        </div>

        <div class='particles__layer particles__layer--2' v-if='isElementVisible'>
            <img class='particles__layer__img' alt='Particles Midground' src='@/media/particles-layer-2-alt.png' width='3772' height='2950'>
        </div>

        <div class='particles__layer particles__layer--3' v-if='isElementVisible'>
            <img class='particles__layer__img' alt='Particles Foreground' src='@/media/particles-layer-3-alt.png' width='3772' height='2950'>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Particles',
    props: {
        showIdleAnimation: {
            default: true,
            type: Boolean
        },
        timeToIdle: {
            default: 2000,
            type: Number
        }
    },
    data: () => ({
        mouse: {
            x: 0,
            y: 0
        },
        pos: {
            x: 0,
            y: 0
        },
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
        isWinActive: document.visibilityState === 'visible',
        isElementVisible: false,
        isIdle: false
    }),
    computed: {
        isPlaying () {
            return this.isWinActive && this.isElementVisible;
        },
        style () {
            return {
                '--x': this.pos.x,
                '--y': this.pos.y
            }
        }
    },
    watch: {
        isPlaying: {
            immediate: true,
            handler: function () {
                this.loop();
            }
        }
    },
    mounted () {
        this.resetIdle();

        this.observer = new window.IntersectionObserver(this.intersect, {
            root: null,
            rootMargin: '20%',
            threshold: 0
        });

        this.observer.observe(this.$el);

        window.addEventListener('resize', this.resize);
        document.addEventListener('visibilitychange', this.visibilitychange);

        this.$parent.$el.addEventListener('mousemove', this.mousemove);
        this.$parent.$el.addEventListener('touchstart', this.touch);
        this.$parent.$el.addEventListener('touchmove', this.touch);
    },
    beforeUnmount () {
        this.observer.unobserve(this.$el);

        window.removeEventListener('resize', this.resize);
        document.removeEventListener('visibilitychange', this.visibilitychange);

        this.$parent.$el.removeEventListener('mousemove', this.mousemove);
        this.$parent.$el.removeEventListener('touchstart', this.touch);
        this.$parent.$el.removeEventListener('touchmove', this.touch);
    },
    methods: {
        loop () {
            if (!this.isPlaying) return;

            if (this.isIdle && this.showIdleAnimation) {
                this.idleDraw();
                requestAnimationFrame(this.loop);
            } else {
                this.mouseDraw();
                requestAnimationFrame(this.loop);
            }
        },

        mouseDraw () {
            this.pos.x += (this.mouse.x - this.pos.x) * 0.05;
            this.pos.y += (this.mouse.y - this.pos.y) * 0.05;
        },

        idleDraw () {
            const idleCycleLength = 15000;
            const circleWidth = 0.5;

            const cycleCompletion = (Date.now() % idleCycleLength) / idleCycleLength;

            const cosine = Math.cos(cycleCompletion * Math.PI * 2);
            const sine = Math.sin(cycleCompletion * Math.PI * 2);

            const x = (cosine / 2 + 0.5) * circleWidth - circleWidth/2;
            const y = circleWidth - (sine / 2 + 0.5) * circleWidth - circleWidth/2;
            
            const idleTime = Date.now() - this.idleStartTime;
            const lerpTime = 5000;

            // if beginning of idle animation, lerp to circle path
            if (idleTime < lerpTime) {
                const minLerpAmt = 0.02;
                const maxLerpAmt = 0.15;
                const lerpAmt = idleTime * (maxLerpAmt - minLerpAmt) / lerpTime + minLerpAmt;

                this.pos.x += (x - this.pos.x) * lerpAmt;
                this.pos.y += (y - this.pos.y) * lerpAmt;
            } else {
                this.pos.x = x;
                this.pos.y = y;
            }
        },
        resetIdle () {
            this.isIdle = false;

            clearTimeout(this.idleTimeout);

            this.idleTimeout = setTimeout(() => {
                this.idleStartTime = Date.now();
                this.isIdle = true;
            }, this.timeToIdle);
        },

        offsetX (x) {
            return (-1 * ((this.winWidth / 2) - x)) / this.winWidth;
        },
        offsetY (y) {
            return (-1 * ((this.winHeight / 2) - y)) / this.winHeight;
        },

        // handlers
        intersect (entries) {
            if (entries[0].isIntersecting) {
                this.isElementVisible = true;
            } else {
                this.isElementVisible = false;
            }
        },
        resize () {
            this.winWidth = window.innerWidth;
            this.winHeight = window.innerHeight
        },
        visibilitychange () {
            this.isWinActive = document.visibilityState === 'visible';
        },
        mousemove (e) {
            this.resetIdle();

            this.mouse.x = this.offsetX(e.clientX);
            this.mouse.y = this.offsetY(e.clientY);
        },
        touch (e) {
            this.resetIdle();

            const touches = e.originalEvent ? e.originalEvent.touches[0] : e.touches[0];
            this.mouse.x = this.offsetX(touches.clientX);
            this.mouse.y = this.offsetY(touches.clientY);
        }
    }
}
</script>
