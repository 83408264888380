<template>
   <section class='splash'>
        <SplashBackground :isModalOpen='isModalOpen' />

        <a class='splash__cta' @click='isModalOpen = !isModalOpen'>Check In</a>

        <transition name='vue-splashmodal'>
            <div v-if='isModalOpen' class='splash__modal'>
                <div class='splash__modal__membrane'>
                    <a class='splash__modal__close' @click='isModalOpen = false' />
                    <div class='splash__modal__content'>
                        <SplashCheckinTier />
                    </div>
                </div>
            </div>
        </transition>
   </section>
</template>

<script>
import SplashBackground from '^/components/elements/SplashBackground.vue';
import SplashCheckinTier from '^/components/tiers/SplashCheckinTier.vue';

export default {
    name: 'Splash',
    data: () => ({
        isModalOpen: false
    }),
    components: {
        SplashBackground,
        SplashCheckinTier
    }
};
</script>