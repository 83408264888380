<template>
    <div class='view view--product'>
        <div class='view__content' data-scroll-section>
            <transition name='vue-fade'>
                <Spinner
                    v-if='!product'
                    :variants='["large", "fixed"]'
                />

                <ProductTier
                    v-else
                    id='product'
                    :product='product'
                    :features='features'
                    :linkedProducts='linkedProducts'
                    :ctaTextOverride='ctaTextOverride'
                    :waitlistId='waitlistId'
                    :sizeChart='sizeChart'
                    data-scroll-id='product'
                    data-scroll
                />
            </transition>
        </div>

        <div data-scroll-section>
            <Footer id='footer' data-scroll-id='footer' data-scroll />
        </div>
    </div>
</template>


<script>
import Spinner from '^/components/elements/Spinner.vue'
import ProductTier from '^/components/tiers/ProductTier.vue'
import Footer from '^/components/Footer.vue'

export default {
    name: 'ProductView',
    props: {
        slug: {
            type: String,
            required: true
        }
    },
    data: () => ({
        product: null,
        features: [],
        linkedProducts: [],
        ctaTextOverride: '',
        waitlistId: '',
        sizeChart: null
    }),
    created () {
        fetch(`/products/${this.slug}?view=json`).then(response => {
            return response.json()
        }).then(data => {
            this.product = data.product
            this.features = data.features || []
            this.linkedProducts = data.linked_products || []
            this.ctaTextOverride = data.cta_text_override || this.ctaTextOverride
            this.waitlistId = data.waitlist_id || this.waitlistId
            this.sizeChart = data.size_chart || null
        })
    },
    components: {
        Spinner,
        ProductTier,
        Footer
    }
};
</script>