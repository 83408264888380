<template>
    <section class='newsletter'>

        <form class='newsletter__form' @submit.prevent='onNewsletterFormSubmit' v-if='!isSubmitted && !isSubmitting'>
            <div class='newsletter__input'>
                <input class='newsletter__input__field'
                    :class='{
                        "newsletter__input__field--is-submit-attemped": !isSubmitAttempted
                    }'
                    ref='downloadableEmailInput'
                    type='email'
                    :placeholder='klaviyo.inputPlaceholder'
                    tabindex='0'
                    @keyup='onNewsletterInputKeyup' 
                />
            </div>
            <div class='newsletter__cta'>
                <a class='newsletter__cta__cta cta cta--alt'
                    :class='{
                        "cta--disabled": !isValid
                    }'
                    v-text='newsletter.ctaText'
                    aria-label='Subscribe to the Hazbin Hotel newsletter'
                    role='button'
                    tabindex='0'
                    @click.prevent='onNewsletterSubmitClick'
                />
            </div>
            <div v-if='errorText' class='newsletter__error'>
                <p v-text='errorText' />
            </div>
            <div v-else class='newsletter__legal'>
                <p>By clicking "Subscribe," I agree to the <a href='https://a24films.com/terms-of-use' target='_blank' rel='noopener'>terms</a>. For more details, see the <a href='https://a24films.com/privacy-policy' target='_blank' rel='noopener'>Privacy Policy</a>.</p>
            </div>
        </form>
        <template v-else-if='isSubmitting'>
            <div class='newsletter__forminfo'>
                <Spinner class='newsletter__forminfo__spinner' :variants='["large"]' />
            </div>
        </template>
        <template v-else-if='isSubmitted'>
            <div class='newsletter__forminfo'>
                <p class='newsletter__forminfo__title' v-html='newsletter.successHead' />
                <p class='newsletter__forminfo__description' v-html='newsletter.successText' />
            </div>
        </template>

    </section>
</template>


<script>
import Spinner from '^/components/elements/Spinner.vue'

const klaviyo = window.app.klaviyo || {}

export default {
    name: 'NewsletterTier',
    data: () => ({
        isValid: false,
        isSubmitAttempted: false,
        isSubmitting: false,
        isSubmitted: false,
        errorText: '',
        klaviyo: {
            apiKey: klaviyo?.apiKey || 'Uk6tXe', // staging: 'YkpeMF'
            inputPlaceholder: klaviyo?.inputPlaceholder || 'Enter your email...',
            genericError: klaviyo?.genericError || 'There was a problem submitting your email.',
        },
        newsletter: {
            listId: klaviyo?.newsletter?.listId || 'VeaC7G', // staging: 'YzxRwE'
            ctaText: klaviyo?.newsletter?.ctaText || 'Subscribe',
            successHead: klaviyo?.newsletter?.successHead || 'Thanks!',
            successText: klaviyo?.newsletter?.successText || 'You\'re on the list.'
        }
    }),
    methods: {
        onNewsletterFormSubmit () {
            this.submitNewsletterRequest()
        },
        onNewsletterInputKeyup (e) {
            this.isValid = e.target?.validity?.valid
        },
        onNewsletterSubmitClick () {
            this.submitNewsletterRequest()
        },
        submitNewsletterRequest () {
            this.isSubmitAttempted = true
            this.isSubmitting = true

            const email = this.$refs.downloadableEmailInput?.value || ''
            const headers = {
                'accept': 'application/json',
                'revision': '2023-12-15',
                'content-type': 'application/json'
            }
            const klaviyoProfile = {
                'data': {
                    'type': 'profile',
                    'attributes': {
                        'email': email
                    }
                }
            }

            fetch('https://a.klaviyo.com/client/subscriptions/?company_id=' + this.klaviyo.apiKey, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    'data': {
                        'type': 'subscription',
                        'attributes': {
                            'profile': klaviyoProfile
                        },
                        'relationships': {
                            'list': {
                                'data': {
                                    'type': 'list',
                                    'id': this.newsletter.listId
                                }
                            }
                        }
                    }
                })
            }).then(async response => {
                if (!response.ok) {
                    const data = await response.json()
                    this.errorText = data?.errors[0]?.detail || this.klaviyo.genericError
                    this.isSubmitting = false
                    return
                }

                this.isSubmitting = false
                this.isSubmitted = true

            }).catch(() => {
                this.errorText = this.klaviyo.genericError
                this.isSubmitting = false
                this.isSubmitted = false
            })
        }
    },
    components: {
        Spinner
    }
};
</script>