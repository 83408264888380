export default function () {
    var measure = {
        scrollbarWidth: function (addCssScrollbarClass) {
            // adapted from github.com/olmokramer/scrollbar-width.js
            // this solution might be overkill, but i believe it should
            // solve for situations where a browser might not have
            // scrollbars when content is shorter than the viewport, but
            // do show scrollbars when content is taller than viewport.
            // a simpler solution is here:
            // tylercipriani.com/blog/2014/07/12/crossbrowser-javascript-scrollbar-detection/
            var scrollbarWidth = null;

            var div1, div2;

            // IL: the below three lines shouldn't be necessary
            // but leaving here to remind myself in case scrollbar
            // width is returning incorrect, in which case we'll
            // probably need a way to remeasure width once the
            // document has finished loading
            /*
            if (document.readyState === 'loading') {
                return null;
            }
            */

            div1 = document.createElement('div');
            div2 = document.createElement('div');

            if (addCssScrollbarClass) {
                div1.classList.add('test-custom-scrollbars');
            }

            div1.style.width = div2.style.width = div1.style.height = div2.style.height = '100px';
            div1.style.overflow = 'scroll';
            div2.style.overflow = 'hidden';

            document.body.appendChild(div1);
            document.body.appendChild(div2);
            scrollbarWidth = Math.abs(div1.scrollHeight - div2.scrollHeight);
            document.body.removeChild(div1);
            document.body.removeChild(div2);

            return scrollbarWidth;
        }
    };


    var fn = {
        init: function () {
            const scrollbarWidth = measure.scrollbarWidth();
            const isScrollbarVisible = (scrollbarWidth && scrollbarWidth > 0);
            const root = document.documentElement;

            if (scrollbarWidth === 0) {
                root.style.setProperty('--scrollbar-width', 0 + 'px');
            } else if (isScrollbarVisible) {
                const customScrollbarWidth = measure.scrollbarWidth(true);
                root.classList.add('has-scrollbars');

                // if the vanilla scrollbar width and the custom-css-styled
                // scrollbar width are equal, it's probably because the
                // browser doesn't support custom scrollbars, so add the
                // pixel value of the scrollbar width just in case
                if (scrollbarWidth === customScrollbarWidth) {
                    root.style.setProperty('--scrollbar-width', scrollbarWidth + 'px');
                }
            }
        }
    };

    fn.init();
}
