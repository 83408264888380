<template>
    <div class='devgrid grid grid--row-on-phone' v-if='isDevGridVisible'>
        <div v-for='i in 12' :key='i' class='devgrid__col grid__col'>
            <div class='devgrid__col__inner' />
        </div>
    </div>
</template>


<script>
export default {
    name: 'DevGrid',
    data: () => ({
        isDebugMode: true,
        isDevGridVisible: false
    }),
    mounted () {
        if (this.isDebugMode) window.addEventListener('keyup', this.keyup);
    },
    beforeUnmount () {
        if (this.isDebugMode) window.removeEventListener('keyup', this.keyup);
    },
    methods: {
        keyup (e) {
            // ctrl-g toggles grid overlay
            if (e.key == 'g' && e.ctrlKey) {
                this.isDevGridVisible = !this.isDevGridVisible;
            }
        }
    }
};
</script>