<template>
    <div class='view view--product'>
        <div class='view__content' data-scroll-section>
            <transition name='vue-fade'>
                <Spinner
                    v-if='!product'
                    :variants='["large", "fixed"]'
                />

                <ProductTier
                    v-else
                    id='product'
                    :product='product'
                    :features='features'
                    :linkedProducts='linkedProducts'
                    :ctaTextOverride='ctaTextOverride'
                    :waitlistId='waitlistId'
                    :sizeChart='sizeChart'
                    data-scroll-id='product'
                    data-scroll
                />
            </transition>
        </div>

        <div data-scroll-section>
            <Footer id='footer' data-scroll-id='footer' data-scroll />
        </div>
    </div>
</template>


<script>
import Spinner from '^/components/elements/Spinner.vue'
import ProductTier from '^/components/tiers/ProductTier.vue'
import Footer from '^/components/Footer.vue'

export default {
    name: 'ProductPreviewView',
    data: () => ({
        product: window.app?.productData?.product || null,
        features: window.app?.productData?.features || [],
        linkedProducts: window.app?.productData?.linked_products || [],
        ctaTextOverride: window.app?.productData?.cta_text_override || '',
        waitlistId: window.app?.productData?.waitlist_id || '',
        waitlistId: window.app?.productData?.waitlist_id || '',
        sizeChart: window.app?.productData?.size_chart || null,
    }),
    components: {
        Spinner,
        ProductTier,
        Footer
    }
};
</script>