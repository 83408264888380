<template>

    <transition name='vue-fade'>
        <a v-if='cart'
            class='cart-notification'
            :class='notificationClasses'
            @click.prevent='toggleCartDrawer'
            @keydown.enter='toggleCartDrawer'
            tabindex='0'>
            <h6 class='cart-notification__text' v-text='"Cart"' />
            <span class='cart-notification__count' v-text='cartCount' />
        </a>
    </transition>


    <transition name='vue-cart'>
        <div v-show='isCartDrawerOpen' class='cart' :class='cartClasses'>
            
            <transition name='vue-fade'>
                <div  v-if='isCartUpdating' class='cart__spinner'>
                    <Spinner :variants='["large"]' />
                </div>
            </transition>

            <a class='cart__close' aria-label="Close cart drawer" @click='closeCartDrawer' @keydown.enter='toggleCartDrawer' tabindex='0'>
                <SvgIconX/>
            </a>

            <transition name='vue-fade'>

                <div class='cart__content' v-if='cart?.items.length > 0'>
                    <div class='cart__items'>
                        <ul class='cart__items__list' data-lenis-prevent>
                            <li class='cart__item' v-for='(item, i) in cart.items' :key='item.key'>
                                <div class='cart__item__thumb'>
                                    <Media :media='item.featured_image' :isFullbleed='true' />
                                </div>
                                <div class='cart__item__name'>
                                    <div class='cart__item__name__title' v-html='item.title' />
                                    <div class='cart__item__name__subtitle'>
                                        <div v-if='item.variant_title'>
                                            <span v-text='item.variant_title' />
                                        </div>
                                        <a @click='removeLineItem(item)'>Remove</a>
                                    </div>
                                    <div class='cart__item__name__notice' v-text='getProductMetafield("cart_notice", item)' />
                                </div>
                                <div class='cart__item__price'>
                                    <div class='cart__price'>
                                        <span class='cart__price__quant'>
                                            <a v-if='!isAtQuantityLimit(item)' class='cart__price__quant__up' v-text='"&rsaquo;"' @click='increaseLineItem(item)' />
                                            <span class='cart__price__quant__num' v-text='item.quantity' />
                                            <a class='cart__price__quant__down' v-text='"&rsaquo;"' @click='decreaseLineItem(item)' />
                                        </span>
                                        <span class='cart__price__x' v-text='"&times;"' />
                                        <span class='cart__price__sign' v-text='"$"' />
                                        <span class='cart__price__dollars' v-text='getPriceDollars(item.final_price)' />
                                        <span class='cart__price__dot' v-text='"."' />
                                        <span class='cart__price__cents' v-text='getPriceCents(cart.final_price)' />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <transition name='vue-collapse'>
                        <div v-if='cartError' class='cart__error'>
                            <div class='cart__error__inner'>
                                <div class='cart__error__text' v-html='widont(cartError)' />
                            </div>
                        </div>
                    </transition>

                    <ul class='cart__breakdown'>
                        <li class='cart__breakdown__row'>
                            <div class='cart__breakdown__label'>
                                <span class='cart__breakdown__label__text' v-text='"Subtotal"' />
                            </div>
                            <div class='cart__price'>
                                <span class='cart__price__sign' v-text='"$"' />
                                <span class='cart__price__dollars' v-text='getPriceDollars(cart.items_subtotal_price)' />
                                <span class='cart__price__dot' v-text='"."' />
                                <span class='cart__price__cents' v-text='getPriceCents(cart.items_subtotal_price)' />
                            </div>
                        </li>
                        <li v-if='cart.total_discount' class='cart__breakdown__row'>
                            <div class='cart__breakdown__label'>
                                <span class='cart__breakdown__label__text' v-text='"Discount"' />
                            </div>
                            <div class='cart__price cart__price--discount'>
                                <span class='cart__price__sign' v-text='"-$"' />
                                <span class='cart__price__dollars' v-text='getPriceDollars(cart.total_discount)' />
                                <span class='cart__price__dot' v-text='"."' />
                                <span class='cart__price__cents' v-text='getPriceCents(cart.total_discount)' />
                            </div>
                        </li>
                        <li class='cart__breakdown__row'>
                            <div class='cart__breakdown__label'>
                                <span class='cart__breakdown__label__text' v-text='"Tax & Shipping"' />
                            </div>
                            <div class='cart__price'>
                                <span class='cart__price__tbd' v-text='"TBD"' />
                            </div>
                        </li>
                        <li class='cart__breakdown__row'>
                            <div class='cart__breakdown__label cart__breakdown__label--total'>
                                <span class='cart__breakdown__label__text' v-text='"Total"' />
                            </div>
                            <div class='cart__price cart__price--total'>
                                <span class='cart__price__sign' v-text='"$"' />
                                <span class='cart__price__dollars' v-text='getPriceDollars(cart.total_price)' />
                                <span class='cart__price__dot' v-text='"."' />
                                <span class='cart__price__cents' v-text='getPriceCents(cart.total_price)' />
                            </div>
                        </li>
                    </ul>

                    <a href='/checkout' class='cart__cta cta' aria-label='Checkout' role='button' tabindex='0' v-text='"Checkout"' />
                </div>

                <div class='cart__content' v-else-if='cart'>
                    <div class='cart__empty'>
                        <span v-for='i in 2' class='cart__empty__text' :class='`cart__empty__text--${i}`' v-text='"Your cart is empty."' />
                    </div>
                </div>
            </transition>
                
        </div>
    </transition>

</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useCartStore } from '^/stores/cart'
import { widont } from '^/utils/typography'
import Media from '^/components/elements/Media.vue'
import Spinner from '^/components/elements/Spinner.vue'
import SvgIconX from '^/components/svg/SvgIconX.vue'

export default {
    name: 'Cart',
    computed: {
        ...mapState(useCartStore, [
            'cart',
            'cartMetafields',
            'cartError',
            'isCartDrawerOpen',
            'isCartUpdating'
        ]),
        cartCount () {
            let count = 0
            this.cart?.items.forEach(item => count += item.quantity)
            return count
        },
        cartQuantitiesByProduct () {
            const products = {}

            for (const item of this.cart.items) {
                if (products[item.product_id]) {
                    products[item.product_id].quantity += item.quantity
                } else {
                    products[item.product_id] = {
                        quantity: item.quantity,
                        limit: this.getProductMetafield('quantity_limit', item) || null
                    }
                }
            }

            return products
        },
        notificationClasses () {
            return {
                'cart-notification--cart-is-open': this.isCartDrawerOpen
            }
        },
        cartClasses () {
            return {
                'cart--is-open': this.isCartDrawerOpen
            }
        },
    },
    created () {
        this.getCart()
    },
    methods: {
        ...mapActions(useCartStore, [
            'getCart',
            'increaseLineItem',
            'decreaseLineItem',
            'removeLineItem',
            'closeCartDrawer',
            'toggleCartDrawer'
        ]),
        getPriceDollars (price) {
            return Math.floor(price / 100)
        },
        getPriceCents (price) {
            return price % 100 || '00'
        },
        isAtQuantityLimit(item) {
            const productId = item.product_id
            const productQtyObj = this.cartQuantitiesByProduct.hasOwnProperty(productId) ? this.cartQuantitiesByProduct[productId] : null

            return productQtyObj && productQtyObj.limit && productQtyObj.quantity >= productQtyObj.limit
        },
        getProductMetafield (fieldName, item) {
            const productId = item.product_id
            const productMetafields = this.cartMetafields.items

            if (productMetafields.hasOwnProperty(productId) &&
                productMetafields[productId].hasOwnProperty(fieldName)) {
                return productMetafields[productId][fieldName]
            }

            return null
        },
        widont: widont
    },
    components: {
        Media,
        Spinner,
        SvgIconX
    }
};
</script>