import 'vite/modulepreload-polyfill';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Scrollbars from '^/helpers/Scrollbars';
import App from '^/App.vue';
import router from '^/router';

Scrollbars();

const pinia = createPinia();

createApp(App)
    .use(router)
    .use(pinia)
    .mount('#app');