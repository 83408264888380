<template>
    <template v-if='isShowSplash'>

        <Splash />

    </template>

    <template v-else>

        <Header id='header' />

        <Cart />

        <section class='main'>
            <router-view v-slot='{ Component, route }'>
                <transition
                    :name='"vue-" + (route.meta.transition || "fade")'
                    @enter='onTransitionEnter'
                    @afterLeave="onTransitionAfterLeave">
                    <component :is='Component' :key='route.path' />
                </transition>
            </router-view>
        </section>

        <DevGrid />

    </template>
</template>


<script>
import { mapActions } from 'pinia'
import debounce from 'lodash/debounce'

import { useScrollStore } from '^/stores/scroll'

import Header from '^/components/Header.vue'
import Cart from '^/components/Cart.vue'
import Splash from '^/components/Splash.vue'
import DevGrid from '^/components/DevGrid.vue'

export default {
    name: 'App',
    data: () => ({
        isShowSplash: window.app?.isSplashEnabled || false,
    }),
    mounted () {
        this.setupScroller()
        
        window.addEventListener('resize', this.resize)
        window.addEventListener('orientationchange', this.orientationchange)
        window.addEventListener('appResize', this.appResize)
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.resize)
        window.removeEventListener('orientationchange', this.orientationchange)
        window.removeEventListener('appResize', this.appResize)
    },
    methods: {
        ...mapActions(useScrollStore, [
            'setupScroller',
            'scrollTo',
            'updateScroller',
            'updatePageSizes'
        ]),
        appResize () {
            this.updatePageSizes()
        },

        onTransitionEnter () {
            if (!this.$route.hash) {
                this.scrollTo(0)
            }
        },
        onTransitionAfterLeave () {
            this.updateScroller()

            if (this.$route.hash) {
                this.scrollTo(this.$route.hash)
            }
        },

        // create an abstracted resize event that gets triggered
        // on (debounced) resize and orientation change
        resize: debounce(() => {
            const resizeEvent = new Event('appResize')
            window.dispatchEvent(resizeEvent)
        }, 300),
        orientationchange () {
            const resizeEvent = new Event('appResize')
            window.dispatchEvent(resizeEvent)
        }
    },
    components: {
        Header,
        Cart,
        Splash,
        DevGrid
    }
}
</script>