<template>
    <div class='splash__checkin'>

    <transition name='crossfade'>
        <div v-if='!showSuccess' class='splash__checkin__step'>
            <img class='splash__checkin__image splash__checkin__image--logo' alt='Hazbin Hotel logo' src='@/media/splash-logo.png' width='1339' height='777'>

            <div class='splash__checkin__text splash__checkin__text--email'>
                <p><strong>Let's make a deal.</strong></p>
                <p>Enter your email and we'll send you updates, straight from hell.</p>
                <p>Welcome gift included.</p>
            </div>

            <form @submit.prevent='onSubmit' class='splash__checkin__form'>
                
                <div class='splash__field splash__field--hidden'>
                    <input 
                        v-model='form.list'
                        type='hidden'
                        name='g' />
                </div>
                
                <div class='splash__field splash__field--text splash__field--with-submit'>
                    <input
                        v-model='form.email'
                        ref='email'
                        class='splash__field__input splash__field__input--email'
                        type='email' name='email'
                        placeholder='concierge@hazbinhotel.com'
                        required />

                    <button
                        type='submit'
                        name='submit'
                        class='splash__field__submit'
                        value='' />
                </div>

                <div class='splash__field splash__field--errors'>
                    <transition name='form-error-slide'>
                        <div v-if='errorMsg' class='splash__field__error'>
                            <div class='splash__field__error__membrane' v-html='errorMsg' />
                        </div>
                    </transition>
                </div>

                <div class='splash__field splash__field--checkbox'>
                    <input
                        v-model='form.terms'
                        ref='terms'
                        class='splash__field__input splash__field__input--checkbox'
                        type='checkbox'
                        name='accept-terms'
                        id='splash__checkin__form__input__accept-terms'
                        required />

                    <label for='splash__checkin__form__input__accept-terms'>
                        I agree to the <a href='https://a24films.com/terms-of-use' target='_blank' rel='noopener'>terms</a>. For more details, see the <a href='https://a24films.com/privacy-policy' target='_blank' rel='noopener'>Privacy Policy</a>.
                    </label>
                </div>
            </form>
        </div>


        <div v-else class='splash__checkin__step'>
            <img class='splash__checkin__image splash__checkin__image--poster' alt='Hazbin Hotel poster' src='@/media/splash-poster.png' width='1410' height='892'>

            <div class='splash__checkin__text splash__checkin__text--poster'>
                <p>Check Your Inbox. Something's Waiting.</p>
                <p><strong>See you in&nbsp;hell.</strong></p>
            </div>
        </div>
    </transition>

</div>
</template>

<script>
export default {
    name: 'SplashCheckinTier',
    data: () => ({
        form: {
            list: 'VeaC7G',
            email: null,
            terms: false
        },
        showSuccess: false,
        isEmailValid: true,
        isTermsValid: true,
        isOtherError: false
    }),
    computed: {
        errorMsg () {
            if (this.isOtherError) {
                return 'There was a problem submitting your email';
            } else if (!this.isEmailValid && !this.isTermsValid) {
                return 'Please enter a valid email & agree to the terms';
            } else if (!this.isEmailValid) {
                return 'Please enter a valid email address';
            } else if (!this.isTermsValid) {
                return 'Please agree to the terms below';
            } else {
                return null;
            }
        }
    },
    mounted () {
        if (!this.showSuccess) this.setupValidation();
    },
    methods: {
        async onSubmit (e) {
            e.preventDefault();

            const data = {
                g: this.form.list,
                email: this.form.email
            };
        
            const body = Object.keys(data).reduce((str, key) => {
                str.append(key, data[key]);
                return str;
            }, new URLSearchParams());
        
            return fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Headers': '*',
                    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
                },
                body
            })
            .then(res => res.json())
            .then(() => {
                this.showSuccess = true;

                if (window.rudderanalytics) {
                    window.rudderanalytics.identify(null, {
                        email: this.form.email
                    });
                    window.rudderanalytics.track('List Signup', {
                        list_id: this.form.list,
                        platform: 'klaviyo',
                        name: 'Hazbin Hotel Splash Signup'
                    });
                }
            })
            .catch(() => this.isOtherError = true);
        },

        setupValidation () {
            // a bit messy, but works for now
            const $vm = this;

            $vm.$refs.email.addEventListener('invalid', (function () {
                return function (e) {
                    e.preventDefault();
                    $vm.isEmailValid = false;
                    $vm.$refs.email.addEventListener('keyup', () => {
                        $vm.isEmailValid = $vm.$refs.email.checkValidity();
                    });
                };
            })(), true);

            $vm.$refs.terms.addEventListener('invalid', (function () {
                return function (e) {
                    e.preventDefault();
                    $vm.isTermsValid = false;
                    $vm.$refs.terms.addEventListener('click', () => {
                        $vm.isTermsValid = $vm.$refs.terms.checkValidity();
                    });
                };
            })(), true);
        }
    }
};
</script>