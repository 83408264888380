<template>
    <section class='about'>

        <!-- particle background -->
        <Particles />

        <!-- heading -->
        <div class='about__heading grid'>
            <div class='about__heading__col about__heading__col--heading grid__col'>
                <h2 v-for='i in 2' class='about__heading__title' :class='`about__heading__title--${i}`'>
                    <div class='about__heading__title__line about__heading__title__line--1'>
                        How
                    </div>
                    <div class='about__heading__title__line about__heading__title__line--2'>
                        To
                    </div>
                    <div class='about__heading__title__line about__heading__title__line--3'>
                        Watch
                    </div>
                </h2>

                <div class='about__heading__bg'>
                    <img class='about__heading__bg__img about__heading__bg__img--phone' src='@/media/devices-phone.png' alt='' loading='lazy' />
                    <img class='about__heading__bg__img about__heading__bg__img--tv' src='@/media/devices-tv-blue.png' alt='' loading='lazy' />
                </div>
            </div>
 
            <div class='about__heading__col about__heading__col--copy grid__col'>
                <div class='about__heading__copy copy'>
                    <p>You’re in luck. The first two episodes are now available on the <a href='https://app.a24films.com/product/hazbin-hotel-early-access/339?utm_source=hazbinhotel.com&utm_medium=referral&utm_campaign=hazbin-early-access&utm_content=how-to-watch-section' target='_blank' rel='noopener'>A24&nbsp;App</a>.</p>
                    <p class='about__heading__copy__lighter'>And the gates to hell are officially open. Watch Season One now on <a href='https://www.amazon.com/dp/B0CLM8SM8T' target='_blank' rel='noopener'>Prime&nbsp;Video</a>.</p>
                    <p class='about__heading__copy__lighter'>Enjoy&nbsp;😈</p>
                </div>
            </div>
        </div>

        <div class='about__main'>
            <!-- <div class='about__main__streaming'>
                <SvgTextStreamingOptions />
            </div> -->

            <div class='about__main__trailer'>
                <VideoBlock
                    :posterSrc='trailerPosterImg'
                    videoType='youtube' 
                    embedId='rYTVBSeTLUQ'
                    caption='Announcement' />
            </div>
        </div>
    </section>
</template>

<script>
import trailerPosterImg from '@/media/trailer-poster.jpg';

import VideoBlock from '^/components/blocks/VideoBlock.vue';
import Particles from '^/components/elements/Particles.vue';
import SvgTextStreamingOptions from '^/components/svg/SvgTextStreamingOptions.vue';

export default {
    name: 'AboutTier',
    computed: {
        trailerPosterImg () {
            return trailerPosterImg;
        }
    },
    components: {
        VideoBlock,
        Particles,
        SvgTextStreamingOptions
    }
};
</script>