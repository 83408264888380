<template>
    <div class="media" :class="{'media--fullbleed': isFullbleed}">
        <transition name="vue-fade">
            <template v-if="isVideo">
                <video class="media__video media__media"
                    ref="video"
                    muted loop autoplay playsinline
                    :style="focusStyle"
                    :preload="(srcVideo ? 'auto' : 'none')">
                    <source v-if="srcVideo" :src="srcVideo" type="video/mp4">
                </video>
            </template>

            <template v-else-if="isImage">
                <img class="media__img media__media"
                    ref="image"
                    crossorigin="anonymous"
                    :alt="alt || media.alt"
                    :style="focusStyle" 
                    :src="src"
                    :srcset="srcSet"
                    :sizes="Math.max(elSize.width, elSize.height) + 'px'"
                    :width="media.width"
                    :height="media.height"
                    @load='onImgLoad'>
            </template>
        </transition>

        <transition name="vue-fade">
            <img class="media__placeholder media__media"
                v-if="!isLoaded"
                :src="placeholder" 
            />
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        media: {
            type: Object,
            required: true
        },
        alt: {
            type: String,
            default: ''
        },
        isFullbleed: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        isInView: false,
        isLoaded: false,
        isIntersectionObserverSetup: false,
        src: 'data:image/svg+xml,%3Csvg width="1px" height="1px" viewBox="0 0 1 1" version="1.1" xmlns="http://www.w3.org/2000/svg"%3E%3Crect x="0" y="0" width="1" height="1" fill="%230E0415"%3E%3C/rect%3E%3C/svg%3E',
        srcSet: '',
        srcVideo: '',
        elSize: {
            width: 1,
            height: 1
        },
        focus: {
            x: null,
            y: null
        },
        resizeObserver: null,
        intersectionObserver: null
    }),
    computed: {
        isVideo () {
            return this.media.media_type === 'video'
        },
        isImage () {
            return !this.isVideo && this.media.media_type !== 'model' && this.media.media_type !== 'external_video'
        },
        mediaUrl () {
            return this.media?.src || this.media?.url || ''
        },
        focusStyle () {
            const { focus } = this;
            if (focus.x === null || focus.y === null ) return ""
            return `object-position: ${focus.x * 100}% ${focus.y * 100}%`
        },
        placeholder () {
            // url-encoded svg; animated if is inview
            return 'data:image/svg+xml,' + 
                '%3Csvg width="1px" height="1px" viewBox="0 0 1 1" version="1.1" xmlns="http://www.w3.org/2000/svg"%3E' +
                    '%3Crect x="0" y="0" width="1" height="1" fill="%230E0415"%3E%3C/rect%3E' +
                    (this.isInView && this.mediaUrl ? '%3Crect x="0" y="0" width="1" height="1" fill="%239E91B922"%3E%3Canimate attributeName="width" attributeType="XML" dur="0.75s" from="0" to="1" fill="freeze" begin="0s" repeatCount="indefinite" /%3E%3C/rect%3E' : '') +
                '%3C/svg%3E'
        }
    },
    mounted () {
        this.resizeObserver = new ResizeObserver(this.onResize)
        this.resizeObserver.observe(this.$el)
    },
    beforeUnmount () {
        this.resizeObserver?.disconnect()
        this.intersectionObserver?.disconnect()
    },
    methods: {
        onResize (entries) {
            const elWidth = entries[0].contentRect.width
            const elHeight = entries[0].contentRect.height

            this.elSize = {
                width: elWidth,
                height: elHeight
            }

            // when media is in a modal or an element that is mounted before
            // displayed, the intersection observer will not always first properly
            // once it's actually visible. since the resize observer always fires
            // once item is properly visible, we can wait to init the intersection
            // observer on the first resize observer event
            if (!this.isIntersectionObserverSetup && elWidth > 0 && elHeight > 0) {
                const ioRoot = this.$el.closest('ion-content')?.scrollEl || null
                this.intersectionObserver = new window.IntersectionObserver(this.onIntersect, {
                    root: ioRoot,
                    rootMargin: '200%',
                    threshold: 0
                })
                this.intersectionObserver.observe(this.$el)
                this.isIntersectionObserverSetup = true
            }
        },
        onIntersect (entries) {
            if (!entries[0].isIntersecting) return
            this.isInView = true

            const src = this.mediaUrl

            if (this.isVideo) {
                this.srcVideo = src
                this.isLoaded = true
            } else {
                const sizes = {
                    large: 2000,
                    medium: 1200,
                    small: 800
                }
                const thumbs = {
                    large: src.replace(/(\.[^\.]*$)/, `_${sizes.large}x$1`),
                    medium: src.replace(/(\.[^\.]*$)/, `_${sizes.medium}x$1`),
                    small: src.replace(/(\.[^\.]*$)/, `_${sizes.small}x$1`)
                }

                this.elSize = {
                    width: entries[0].boundingClientRect.width,
                    height: entries[0].boundingClientRect.height
                }

                this.src = thumbs.large
                this.srcSet = thumbs.large + ' ' + sizes.large + 'w, ' + thumbs.medium + ' ' + sizes.medium + 'w, ' + thumbs.small + ' ' + sizes.small + 'w'
            }

            if (this.intersectionObserver) this.intersectionObserver.unobserve(this.$el)
        },
        onImgLoad () {
            this.isLoaded = true
        }
    }
}
</script>
